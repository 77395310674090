import React, { useState } from 'react';
import './Register.css';
import { Link } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes agregar la lógica para validar el usuario y la contraseña
    if (username === 'usuario' && password === 'contraseña') {
      // Si las credenciales son correctas, puedes redirigir a la página de inicio o realizar alguna acción
      console.log('Registro de sesión exitoso');
    } else {
      setError('Usuario o contraseña incorrectos');
    }
  };

  return (
    <div className="register-container">
      <h2>Registrate</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="register-input"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="register-input"
          />
        </div>
        <div className="form-group">
          <button type="submit" className="register-button">
            Registrate
          </button>
        </div>
      </form>
      <p>
        ¿Ya tienes una cuenta?{' '}
        <Link to="/login">Inicia sesión aquí</Link>
      </p>
    </div>
  );
};

export default Register;
