import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const App = () => {
  const [image, setImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef();
  const mediaStreamRef = useRef();

  useEffect(() => {
    handleTakePhoto();

    // Cleanup function
    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const handleTakePhoto = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
        }
      });
      videoRef.current.srcObject = mediaStream;
      mediaStreamRef.current = mediaStream;
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.play();
      });
    } catch (error) {
      console.error('Error al acceder a la cámara:', error);
    }
  };

  const handleCapture = async () => {
    takePhoto();
  };

  const takePhoto = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL('image/jpeg');
    setImage(dataURL);
    setShowModal(true);
  };

  const handleTakeAnotherPhoto = async () => {
    setImage(null);
    setShowModal(false);
    handleTakePhoto();
  };

  const handleSendPhoto = async () => {
    if (image) {
      const dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      };
      
      // Convertir la URL de datos de la imagen a un archivo de imagen
      const imageFile = dataURLtoFile(image, 'image.jpeg');
      
      // Crear FormData y agregar el archivo de imagen
      const formData = new FormData();
      formData.append('image', imageFile);
      
  
      try {
        //const response = await fetch('http://localhost:5000/upload', {
        const response = await fetch('https://react.needpluto.io/upload', {
          method: 'POST',
          body: formData
        });
  
        if (response.ok) {
          console.log('Foto enviada exitosamente');
          setShowModal(false);
        } else {
          throw new Error('Error al enviar la foto');
        }
      } catch (error) {
        console.error('Error al enviar la foto:', error);
      }
    } else {
      console.log('No hay ninguna imagen para enviar');
    }
  };
  
  

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <button onClick={handleCapture} style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: '1', width: '50px', height: '50px', borderRadius: '50%', backgroundColor: 'lightgray', border: '1px black solid' }}></button>
      <Link to="/login" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1', backgroundColor: 'lightgray', border: 'solid black 1px', borderRadius: '8px', padding: '10px', textDecoration: 'none', color: 'black' }}>Login</Link>
      {image && ( 
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '2', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={image} alt="Capturada" style={{ maxHeight: '100%', maxWidth: '100%' }} />
        </div>
      )}
      {showModal && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', maxWidth: '300px', textAlign: 'center' }}>
            <p>¿Seguro que quieres enviar esta foto?</p>
            <button onClick={handleTakeAnotherPhoto} style={{ marginRight: '5%', width: '47.5%', backgroundColor: 'red', color: 'white', fontWeight: 'bold', border: 'none', borderRadius: '10px', padding: '10px' }}>Tomar otra foto</button>
            <button onClick={handleSendPhoto} style={{ width: '47.5%', backgroundColor: 'green', color: 'white', fontWeight: 'bold', border: 'none', borderRadius: '10px', padding: '10px' }}>Enviar foto</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
