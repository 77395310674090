import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot desde react-dom
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa BrowserRouter, Routes y Route
import './index.css';
import App from './App';
import Login from './Login'; // Importa el componente Login
import Register from './Register';

import reportWebVitals from './reportWebVitals';


const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router> 
      <Routes> 
        <Route exact path="/" element={<App />} /> {/* Renderiza App si la ruta es / */}
        <Route exact path="/login" element={<Login />} /> {/* Renderiza Login si la ruta es /login */}
        <Route exact path="/register" element={<Register />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
